body{
  color: #555;
}

.menu-item{
  cursor: pointer;
}

.sec-title{
  font-weight: 300;
  padding: 10px 10px;
  background: #EEE;
  border-left: 4px solid #007bff;
}

.card-selectable:hover{
  cursor: pointer;
  background: #EEE;
  transform: scale(1.02)
}

.card-selectable.selected{
  color: #FFF;
  background: #007bff;
}

.issue-like:hover{ cursor: pointer; }
.modal-xxl{
  width: 95%;
  max-width: 95%;
}

.side-menu{
  position: fixed;
  top: 0;
  right: 0;
  min-width: 400px;
  min-height: 100vh;
  padding: 0 20px;
  /* background: rgba(79, 37, 130, .9); */
  background: rgba(0, 123, 255, 1);
  z-index: 100;
}

.side-menu-overlay{
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.2);
  z-index: 90;
}

.menu-close{
  color: #FFF;
  text-align: center;
  margin: 10px 10px 0 10px;
  padding: 15px 15px 5px 15px;
  font-size: 30px;
  cursor: pointer;
}

.menu-item{
  color: #444;
  text-align: left;
  margin: 20px;
  background: #FFF;
  border-radius: 10px;
  cursor: pointer;
}
.menu-item > a,
.menu-item > span{
  display: block;
  padding: 20px;
  color: inherit;
  text-decoration: none;
}

.menu-item .icon{
  float: left; 
  width: 50px;
  height: 45px;
  margin-right: 15px;
}








/* Chat bubbles */
.message small b{
  opacity: .7;
  font-weight: 300;
}
.message .bg-kellogg,
.offer-box.bg-kellogg{
  border: none;
  background: rgba(231, 232, 252, 0.5);
  /* box-shadow: -2px 3px 5px 1px rgba(79, 37, 130, 0.2); */
  box-shadow: 0px 3px 3px 0px rgb(0,0,0,.1);
  border: 1px solid #E6E6E6;
}
.message .bg-white,
.offer-box.bg-white{
  border: none;
  background: #f9f9f9 !important;
  box-shadow: 0px 3px 3px 0px rgb(0,0,0,.1);
  border: 1px solid #E6E6E6;
}

/* ARROW */
.message .card:after, .message .card:before {
border: solid transparent;
content: " ";
height: 0;
width: 0;
position: absolute;
pointer-events: none;
}
.message .card.float-left{ border-top-left-radius: 0; }
.message .card.float-right{ border-top-right-radius: 0; }

.message .card.float-left:after {
  right: 100%;
top: 10px;
border-color: rgba(136, 183, 213, 0);
  border-top-color: #f9f9f9;
  border-right-color: #f9f9f9;
  border-width: 10px;
  margin-top: -10px;
  margin-right: -3px;
}
.message .card.float-left:before {
  right: 100%;
top: 10px;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #E6E6E6;
  border-right-color: #E6E6E6;
  border-width: 10px;
  margin-top: -11px;
}

.message .card.float-right:after {
  left: 100%;
top: 10px;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: rgb(243, 244, 254);
  border-left-color: rgb(243, 244, 254);
  border-width: 10px;
  margin-top: -10px;
  margin-left: -3px;
}
.message .card.float-right:before {
  left: 100%;
top: 10px;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #E6E6E6;
  border-left-color: #E6E6E6;
  border-width: 10px;
  margin-top: -11px;
}


/* BUILDER */
.offer-box .btn-secondary {
  background: #DDD;
  border: 1px solid #DDD;
  color: #222;
  opacity: .7;
}
.offer-box .btn-secondary:hover {
  background: #D6D6D6;
  border: 1px solid #D6D6D6;
  color: #222;
  opacity: 1;
}


/* Theming */
.text-kellogg{
  color:#4F2582;
}
.bg-kellogg,
.badge-kellogg{
  background: #4F2582;
}

.btn-kellogg {
  color: #fff;
  background-color: #612ea0;
  border-color: #612ea0;
}

.btn-kellogg:hover {
  color: #fff;
  background-color: #4F2582;
  border-color: #4F2582;
}

.btn-kellogg:focus, .btn-kellogg.focus {
  box-shadow: 0 0 0 0.2rem rgba(57, 21, 111, 0.5)
}

.btn-kellogg.disabled, .btn-kellogg:disabled {
  color: #fff;
  background-color: #4F2582;
  border-color: #4F2582;
}

.btn-kellogg:not(:disabled):not(.disabled):active, .btn-kellogg:not(:disabled):not(.disabled).active,
.show > .btn-kellogg.dropdown-toggle {
  color: #fff;
  background-color: #4F2582;
  border-color: #4F2582;
}

.btn-kellogg:not(:disabled):not(.disabled):active:focus, .btn-kellogg:not(:disabled):not(.disabled).active:focus,
.show > .btn-kellogg.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 42, 132, 0.5);
}

.btn-outline-kellogg {
  color: #612ea0;
  border-color: #612ea0;
  background-color: #FFF;
}

.btn-outline-kellogg:hover {
  color: #fff;
  background-color: #4F2582;
  border-color: #4F2582;
}

.btn-outline-kellogg:focus, .btn-outline-kellogg.focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 42, 132, 0.5);
}

.btn-outline-kellogg.disabled, .btn-outline-kellogg:disabled {
  color: #4F2582;
  background-color: transparent;
}

.btn-outline-kellogg:not(:disabled):not(.disabled):active, .btn-outline-kellogg:not(:disabled):not(.disabled).active,
.show > .btn-outline-kellogg.dropdown-toggle {
  color: #fff;
  background-color: #4F2582;
  border-color: #4F2582;
}

.btn-outline-kellogg:not(:disabled):not(.disabled):active:focus, .btn-outline-kellogg:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-kellogg.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 42, 132, 0.5);
}